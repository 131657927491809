import React, { useRef } from "react";
import { Controller, Scene } from "react-scrollmagic";
import Sequence from "./Sequence";
import styled, {keyframes} from "styled-components";


const ScrollAnimation = keyframes`
  0%, 20% {
    transform: translateY(0) scaleY(1);
  }
  10% {
    opacity: 1;
  }
  100% {
      transform: translateY(36px) scaleY(2);
      opacity: 0.01;
  }
`


const StyledMouse = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 48px;
  opacity: 0.35;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  p {
      margin-top: 16px;
  }
  .mouse {
    width: 40px;
    position: relative;
    z-index: 1;

    .scroll {
      animation: ${ScrollAnimation};
      animation-duration: 1.5s;
      animation-timing-function: cubic-bezier(0.650, -0.550, 0.250, 1.500);
      animation-iteration-count: infinite;
      transform-origin: 50% 20.5px;
      will-change: transform, opacity;
      opacity: 1;
    }
  }
`

export default () => {
    const ref = useRef()
    return <div style={{ marginTop: 96, marginBottom: -32, position: "relative" }}>
        <StyledMouse>
            <svg className="mouse" xmlns="..." viewBox="0 0 76 130" preserveAspectRatio="xMidYmid meet">
                <g fill="none" fillRule="evenodd">
                    <rect width="70" height="118" x="1.5" y="1.5" stroke="#000" strokeWidth="3" rx="36"/>
                    <circle className="scroll" cx="36.5" cy="31.5" r="4.5" fill="#000"/>
                </g>
            </svg>
            <p>Scrolle herunter <i className="far fa-arrow-down" /></p>
        </StyledMouse>
        <Controller>
            <Scene offset={-300} duration="100%" triggerHook="onLeave" pin>
                {progress => (
                    <div style={{ height: "800px", position: "relative", opacity: /*(progress * 1.85) - 0.45*/1 }}>
                        <Sequence ref={ref} progress={progress} />
                    </div>
                )}
            </Scene>
        </Controller>
    </div>
}
