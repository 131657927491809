import React, { forwardRef } from "react"
import ImageArray from "./ImageArray"

const ImageSequence = forwardRef(({ progress }, ref) => {
    const newImages = ImageArray()

    let index = Math.round(progress * 1 * (newImages.length - 1));

    if (newImages[index][1] !== undefined) {
        if (newImages[index][1] === "loading") {
            return null
        } else {
            return newImages.map((item, i) => (
                <div
                    ref={ref}
                    key={i}
                    style={{
                        zIndex: i !== index ? 0 : 3,
                        opacity: i === index ? progress * 2.5 : 0,
                        position: "absolute",
                        height: "100%",
                        width: "100%",
                        backgroundImage: `url('${item[0] ? item[0].src : null}')`,
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat"
                    }}
                />
            ));
        }
    }
});

export default ImageSequence;
