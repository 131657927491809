import A1 from "./ezgif-frame-001.jpg";
import A2 from "./ezgif-frame-002.jpg";
import A3 from "./ezgif-frame-003.jpg";
import A4 from "./ezgif-frame-004.jpg";
import A5 from "./ezgif-frame-005.jpg";
import A6 from "./ezgif-frame-006.jpg";
import A7 from "./ezgif-frame-007.jpg";
import A8 from "./ezgif-frame-008.jpg";
import A9 from "./ezgif-frame-009.jpg";

import A10 from "./ezgif-frame-010.jpg";
import A11 from "./ezgif-frame-011.jpg";
import A12 from "./ezgif-frame-012.jpg";
import A13 from "./ezgif-frame-013.jpg";
import A14 from "./ezgif-frame-014.jpg";
import A15 from "./ezgif-frame-015.jpg";
import A16 from "./ezgif-frame-016.jpg";
import A17 from "./ezgif-frame-017.jpg";
import A18 from "./ezgif-frame-018.jpg";
import A19 from "./ezgif-frame-019.jpg";

import A20 from "./ezgif-frame-020.jpg";
import A21 from "./ezgif-frame-021.jpg";
import A22 from "./ezgif-frame-022.jpg";
import A23 from "./ezgif-frame-023.jpg";
import A24 from "./ezgif-frame-024.jpg";
import A25 from "./ezgif-frame-025.jpg";
import A26 from "./ezgif-frame-026.jpg";
import A27 from "./ezgif-frame-027.jpg";
import A28 from "./ezgif-frame-028.jpg";
import A29 from "./ezgif-frame-029.jpg";

import A30 from "./ezgif-frame-030.jpg";
import A31 from "./ezgif-frame-031.jpg";
import A32 from "./ezgif-frame-032.jpg";
import A33 from "./ezgif-frame-033.jpg";
import A34 from "./ezgif-frame-034.jpg";
import A35 from "./ezgif-frame-035.jpg";
import A36 from "./ezgif-frame-036.jpg";
import A37 from "./ezgif-frame-037.jpg";
import A38 from "./ezgif-frame-038.jpg";
import A39 from "./ezgif-frame-039.jpg";

import A40 from "./ezgif-frame-040.jpg";
import A41 from "./ezgif-frame-041.jpg";

export const AirPods = Array.of(
    A1,
    A2,
    A3,
    A4,
    A5,
    A6,
    A7,
    A8,
    A9,

    A10,
    A11,
    A12,
    A13,
    A14,
    A15,
    A16,
    A17,
    A18,
    A19,

    A20,
    A21,
    A22,
    A23,
    A24,
    A25,
    A26,
    A27,
    A28,
    A29,

    A30,
    A31,
    A32,
    A33,
    A34,
    A35,
    A36,
    A37,
    A38,
    A39,

    A40,
    A41
);
